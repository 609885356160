var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ShadowCard',{attrs:{"param":{
    name: 'OTC Prices Entry',
    showButtons: true,
  },"isLoading":_vm.isLoading}},[_c('EditableTable',{attrs:{"fields":_vm.fields,"storeData":_vm.storeData,"storePath":_vm.storePath,"id_property":_vm.id_property,"item_name":'Prices',"initialSort":[
      { field: 'date', type: 'desc' },
      { field: 'asset_id', type: 'asc' },
    ]}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }