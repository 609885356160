<template>
  <ShadowCard
    :param="{
      name: 'OTC Prices Entry',
      showButtons: true,
    }"
    :isLoading="isLoading">
    <EditableTable
      :fields="fields"
      :storeData="storeData"
      :storePath="storePath"
      :id_property="id_property"
      :item_name="'Prices'"
      :initialSort="[
        { field: 'date', type: 'desc' },
        { field: 'asset_id', type: 'asc' },
      ]" />
  </ShadowCard>
</template>

<script>
import ShadowCard from "../../../presentationalComponents/ShadowCard.vue";
import EditableTable from "../account/EditableTable.vue";

export default {
  name: "prices",
  components: { EditableTable, ShadowCard },
  data() {
    return {
      securities: [],
      isLoading: true,
      storeData: "PRICES",
      storePath: "prices",
      id_property: "asset_id",
      fields: [
        {
          type: "text",
          field: "asset_label",
          label: "Asset ID",
          thClass: "text-center",
          tdClass: "text-left",
          editable: false,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "",
            trigger: "enter",
            filterDropdownItems: this.securitiesList,
          },
        },
        {
          field: "date",
          label: "Date",
          tdClass: "text-center",
          thClass: "text-center",
          editable: true,
          formatFn: this.dateFormatFn,
          filterOptions: {
            enabled: true,
            placeholder: "Date",
            trigger: "keyup",
          },
        },

        {
          type: "number",
          field: "last",
          label: "Last",
          prefix: "$",
          thClass: "text-center",
          tdClass: "text-right",
          editable: true,
          precision: 6,
          filterOptions: {
            enabled: true,
            placeholder: "",
            trigger: "keyup",
          },
        },
        {
          type: "number",
          field: "prev_close",
          label: "Prev Close",
          prefix: "$",
          tdClass: "text-right",
          thClass: "text-center",
          editable: true,
          precision: 6,
          filterOptions: {
            enabled: true,
            placeholder: "",
            trigger: "keyup",
          },
        },
        {
          type: "number",
          field: "open",
          label: "Open",
          prefix: "$",
          thClass: "text-center",
          tdClass: "text-right",
          editable: true,
          precision: 6,
          filterOptions: {
            enabled: true,
            placeholder: "",
            trigger: "keyup",
          },
        },
        {
          type: "number",
          field: "low",
          label: "Low",
          prefix: "$",
          thClass: "text-center",
          tdClass: "text-right",
          editable: true,
          precision: 6,
          filterOptions: {
            enabled: true,
            placeholder: "",
            trigger: "keyup",
          },
        },
        {
          type: "number",
          field: "high",
          label: "High",
          prefix: "$",
          thClass: "text-center",
          tdClass: "text-right",
          editable: true,
          precision: 6,
          filterOptions: {
            enabled: true,
            placeholder: "",
            trigger: "keyup",
          },
        },
        {
          type: "number",
          field: "volume",
          label: "Volume",
          thClass: "text-center",
          tdClass: "text-right",
          editable: true,
          precision: 0,
          filterOptions: {
            enabled: true,
            placeholder: "",
            trigger: "keyup",
          },
        },
        {
          type: "number",
          field: "int_acc",
          label: "Int_acc",
          thClass: "text-center",
          tdClass: "text-right",
          editable: true,
          precision: 2,
          filterOptions: {
            enabled: true,
            placeholder: "int_acc",
            trigger: "keyup",
          },
        },
        {
          type: "number",
          field: "ytm",
          label: "Ytm",
          thClass: "text-center",
          tdClass: "text-right",
          editable: true,
          precision: 2,
          filterOptions: {
            enabled: true,
            placeholder: "",
            trigger: "keyup",
          },
        },

        {
          field: "Actions",
          label: "Actions",
          actions: "actions",
          thClass: "text-center",
          tdClass: "text-center",
          html: true,
        },
      ],
    };
  },
  mounted() {
    this.$store.commit(`SET_TABLE_EDIT_MODE`, "false");
    this.setEditableFields();
  },
  methods: {
    setEditableFields() {
      let editableFields = this.fields.filter((field) => field.editable);

      this.$store.commit("SET_TABLE_EDITABLEFIELDS", editableFields);
    },
    updateFilterDropdown(fieldName, dropdownItems) {
      const fieldToUpdate = this.fields.find(
        (field) => field.field === fieldName
      );
      if (fieldToUpdate && fieldToUpdate.filterOptions) {
        fieldToUpdate.filterOptions.filterDropdownItems = dropdownItems;
      }
    },
  },
  computed: {
    editable() {
      let editableFields = this.fields.filter((field) => field.editable);

      return editableFields;
    },
    securitiesList() {
      return this.$store.state.securitiesList.map((security) => {
        let identifier = security.figi || security.isin || security.bbg_ticker;
        return {
          value: security.asset_id,
          text: security.name + " (" + security.bbg_ticker + ")",
          attrs: {
            identifier: identifier,
          },
        };
      });
    },
  },

  watch: {
    securitiesList: {
      immediate: true,
      handler(newVal) {
        this.updateFilterDropdown("asset_id", newVal);
      },
    },
  },
};
</script>

<style></style>
